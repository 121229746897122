@tailwind base;

h1 {
  @apply text-4xl font-body font-bold;
}

h2 {
  letter-spacing: -1px;
  @apply text-4xl font-heading;
}

@tailwind components;
@tailwind utilities;

.container {
  @apply m-auto py-0 px-4;
  max-width: 1024px;
}

body {
  @apply antialiased font-body text-neutral-700;
}

.transform-preserve-3d {
  transform-style: preserve-3d;
}
td, th{
  border: 1px solid;
}
ul,
ul li {
  list-style-type: circle;
  display: list-item;
}

ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

ol,
ol li {
  list-style-type: decimal;
}
ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}
table{
  border: 1px solid;
}

.primary-gradient {
  background: linear-gradient(#f7fafc 0%, #4874ac 0%, #6a93cd 100%);
}

.icon-fixed-width {
  min-width: 24px;
}