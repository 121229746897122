.cube {
  @apply float-left w-1/2 h-1/2 relative transform scale-110;
}

.cube:before {
  content: '';
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  transform-origin: 100% 100%;
  @apply absolute top-0 left-0 w-full h-full bg-secondary-500;
}

.cube2 {
  transform: scale(1.1) rotateZ(90deg);
}
.cube3 {
  transform: scale(1.1) rotateZ(180deg);
}
.cube4 {
  transform: scale(1.1) rotateZ(270deg);
}
.cube2:before {
  animation-delay: 0.3s;
}
.cube3:before {
  animation-delay: 0.6s;
}
.cube4:before {
  animation-delay: 0.9s;
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}
