.navItem {
  @apply p-4 border-l-4 border-transparent transition-colors duration-200;
}

.navItem__collapsed {
  padding-right: 2.5rem;
}

.navItem:hover {
  @apply border-secondary-500 bg-neutral-700;
}

.navItem__active {
  @apply bg-neutral-700 border-secondary-500;
}
